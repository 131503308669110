$(function () {
	'use strict';

	// $('body')
	// 	.on('mouseover focus', '.dropdown', function (event) {
	// 		$(event.target).closest('.dropdown').addClass('dropdown--expanded');
	// 	})
	// 	.on('mouseleave blur', '.dropdown', function (event) {
	// 		$(event.target).closest('.dropdown').removeClass('dropdown--expanded');
	// 	})
	// 	.find('.dropdown__label').attr('tab-index', 0);
	//
	// 	console.log($('body').find('.dropdown__label'));
});
