(function () {
	'use strict';

	// http://www.pjgalbraith.com/drawing-animated-curves-javascript/

	/**
	 * Animates bezier-curve
	 *
	 * @param ctx       The canvas context to draw to
	 * @param x0        The x-coord of the start point
	 * @param y0        The y-coord of the start point
	 * @param x1        The x-coord of the control point
	 * @param y1        The y-coord of the control point
	 * @param x2        The x-coord of the end point
	 * @param y2        The y-coord of the end point
	 * @param duration  The duration in milliseconds
	 */
	function animatePathDrawing (ctx, x0, y0, x1, y1, x2, y2, duration, callback) {
		var start = null;

		var step = function animatePathDrawingStep (timestamp) {
			if (start === null) {
				start = timestamp;
			}

			var delta = timestamp - start;
			var progress = Math.min(delta / duration, 1);

			// Clear canvas
			ctx.clearRect(x0, 0, ctx.canvas.width, ctx.canvas.height * 0.92);

			// Draw curve
			drawBezierSplit(ctx, x0, y0, x1, y1, x2, y2, 0, progress);

			if (progress < 1) {
				window.requestAnimationFrame(step);
			}
			else if (callback) {
				callback();
			}
		};

		window.requestAnimationFrame(step);
	}

	/**
	 * Draws a splitted bezier-curve
	 *
	 * @param ctx       The canvas context to draw to
	 * @param x0        The x-coord of the start point
	 * @param y0        The y-coord of the start point
	 * @param x1        The x-coord of the control point
	 * @param y1        The y-coord of the control point
	 * @param x2        The x-coord of the end point
	 * @param y2        The y-coord of the end point
	 * @param t0        The start ratio of the splitted bezier from 0.0 to 1.0
	 * @param t1        The start ratio of the splitted bezier from 0.0 to 1.0
	 */
	function drawBezierSplit (ctx, x0, y0, x1, y1, x2, y2, t0, t1) {
		ctx.beginPath();

		if (t0 === 0.0 && t1 === 1.0) {
			ctx.moveTo(x0, y0);
			ctx.quadraticCurveTo(x1, y1, x2, y2);
		}
		else if (t0 !== t1) {
			var t00 = t0 * t0;
			var t01 = 1.0 - t0;
			var t02 = t01 * t01;
			var t03 = 2.0 * t0 * t01;

			var nx0 = t02 * x0 + t03 * x1 + t00 * x2;
			var ny0 = t02 * y0 + t03 * y1 + t00 * y2;

			t00 = t1 * t1;
			t01 = 1.0 - t1;
			t02 = t01 * t01;
			t03 = 2.0 * t1 * t01;

			var nx2 = t02 * x0 + t03 * x1 + t00 * x2;
			var ny2 = t02 * y0 + t03 * y1 + t00 * y2;

			var nx1 = lerp (lerp (x0, x1, t0), lerp (x1, x2, t0), t1);
			var ny1 = lerp (lerp (y0, y1, t0), lerp (y1, y2, t0), t1);

			ctx.moveTo(nx0, ny0);
			ctx.quadraticCurveTo(nx1, ny1, nx2, ny2);
		}

		ctx.stroke();
		ctx.closePath();
	}

	/**
	 * Linearly interpolate between two numbers v0, v1 by t
	 */
	function lerp (v0, v1, t) {
		return (1.0 - t) * v0 + t * v1;
	}

	if (document.querySelector('.maturity-curve')) {
		var component = document.querySelector('.maturity-curve');
		var canvas = document.getElementById('maturity-curve-canvas');
		var ctx = canvas.getContext('2d');
		var parentWidth = canvas.parentElement.clientWidth - parseInt(window.getComputedStyle(canvas.parentElement, null).getPropertyValue('padding-left'), 10) * 2;

		canvas.setAttribute('width', parentWidth);
		canvas.setAttribute('height', parentWidth * 0.44);

		ctx.strokeStyle = 'black';
		ctx.lineWidth = 2;

		// Arrow line
		ctx.save();
		ctx.beginPath();
		ctx.moveTo(0, canvas.clientHeight - 12);
		ctx.lineTo(canvas.clientWidth - 1, canvas.clientHeight - 12);
		ctx.stroke();
		ctx.restore();

		// Arrowhead
		ctx.save();
		ctx.beginPath();
		ctx.moveTo(canvas.clientWidth - 11, canvas.clientHeight - 22);
		ctx.lineTo(canvas.clientWidth - 1, canvas.clientHeight - 12);
		ctx.lineTo(canvas.clientWidth - 11, canvas.clientHeight - 2);
		ctx.stroke();
		ctx.restore();

		ctx.strokeStyle = 'var(--color-molten)';

		animatePathDrawing(ctx, 0, canvas.clientHeight * 0.9, canvas.clientWidth * 0.38, canvas.clientHeight * 0.9, canvas.clientWidth * 0.6, canvas.clientHeight * 0.45, 1000, function () {
			animatePathDrawing(ctx, canvas.clientWidth * 0.6, canvas.clientHeight * 0.45, canvas.clientWidth * 0.8, 1, canvas.clientWidth, 1, 1000, function () {
				component.setAttribute('class', component.getAttribute('class') + ' has-complete-curve');
			});
		});
	}
}());
