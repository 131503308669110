function createAccordion(domNode) {
	var rootEl = domNode;
	var buttonEl = rootEl.querySelector('button[aria-expanded]');
	var controlsId = buttonEl.getAttribute('aria-controls');
	var contentEl = document.getElementById(controlsId);
	var isOpen = buttonEl.getAttribute('aria-expanded') === 'true';

	buttonEl.addEventListener('click', onButtonClick);

	function onButtonClick() {
		toggle(!isOpen);
	}

	function toggle(open) {
		if (open === isOpen) {
			return;
		}

		isOpen = open;
		buttonEl.setAttribute('aria-expanded', isOpen);
		contentEl.hidden = !isOpen;
	}

	function open() {
		toggle(true);
	}

	function close() {
		toggle(false);
	}

	document.addEventListener('keyup', function (event) {
		if (event.key === 'Escape') {
			close();
		}
	});

	return {
		open: open,
		close: close,
	};
}

var accordions = document.querySelectorAll('.accordion p');
accordions.forEach(function (accordionEl) {
	createAccordion(accordionEl);
});

document.addEventListener('DOMContentLoaded', function () {
	var warehouseSelector = document.querySelector(
		'#warehouse-picker-select-element'
	);
	if (warehouseSelector) {
		var submitButton = document.querySelector(
			'#warehouse-picker-select-submit-button'
		);
		warehouseSelector.addEventListener('change', function (event) {
			submitButton.click();
		});
	}
});
