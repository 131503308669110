(function ($) {
	'use strict';

	$.fn.instaList = function () {
		return this.each(function () {
			var form = $(this).find('.insta-list__topics-form');

			form.find('button').addClass('structural');

			form.find('select').on('change', function () {
				form.find('[type="submit"]').click();
			});
		});
	};
}(jQuery));
