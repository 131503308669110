(function () {
	'use strict';

	var img = $('.parallax-areas__group--first .parallax-areas__image');
	var area = img.closest('.parallax-areas__area');
	var img2 = img.closest('.parallax-areas').find('.parallax-areas__group--second .parallax-areas__image');

	if (img.length === 0) {
		return;
	}

	function updateImagePos () {
		if (window.matchMedia && !window.matchMedia('screen and (max-width:1200px)').matches) {
			var areaTop = area.offset().top;
			var areaHeight = area.height();
			var viewportHeight = $(window).outerHeight();
			var viewportScroll = $(window).scrollTop();
			var imageHeight = img.outerHeight();
			var topVal;

			// First image
			if (viewportScroll + (viewportHeight / 2) > areaTop + areaHeight - (imageHeight / 2)) {
				topVal = viewportScroll + (viewportHeight / 2) - areaTop - (imageHeight / 2);

				if (topVal < (areaHeight / 2)) {
					if (parseInt(img.css('top')) !== areaHeight / 2) {
						img.css('top', areaHeight / 2);
					}
				}
				else {
					if (parseInt(img.css('top')) !== topVal) {
						img.css('top', topVal);
					}
				}
			}

			// Second image
			if (parseInt(img.css('top')) > areaHeight - (imageHeight / 2)) {
				// Somehow this is ~70px too much :flushed:
				topVal = parseInt(img.css('top')) - (areaHeight / 2) - 70;

				if (parseInt(img2.css('top')) !== topVal) {
					img2.css('top', topVal);
				}
			}
			else if (parseInt(img2.css('top')) !== 0) {
				img2.css('top', 0);
			}

			// Facts
			$('.parallax-areas__fact').each(function () {
				var fact = $(this);

				if (fact.offset().top > fact.parents('.parallax-areas__area').offset().top + 50) {
					fact.addClass('parallax-areas__fact--show');
				}
				else {
					fact.removeClass('parallax-areas__fact--show');
				}
			});
		}

		requestAnimationFrame(updateImagePos);
	}

	requestAnimationFrame(updateImagePos);
}());
