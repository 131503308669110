$(document).ready(function() {

	var fieldsetRandomLength = $('#quick-add-to-cart-random-length');
	var fieldsetFixedLength = $('#quick-add-to-cart-fixed-length');
	var quickAddToCartBlocker = $('.quick-add-to-cart-blocker');
	var closeButton = $('.quick-add-to-cart-close-button ');

	fieldsetFixedLength.on('click', function() {
		toggleFieldset(fieldsetFixedLength, fieldsetRandomLength);
	});
	fieldsetRandomLength.on('click', function() {
		toggleFieldset(fieldsetRandomLength, fieldsetFixedLength);
	});
	closeButton.on('click', function() {
		quickAddToCartBlocker.hide();
	});

	function toggleFieldset(elementToEnable, elementToDisable) {
		elementToEnable.closest('fieldset').find('input[type=\'number\']').prop('disabled', false);
		elementToEnable.closest('fieldset').find('select').prop('disabled', false);
		elementToDisable.closest('fieldset').find('input[type=\'number\']').prop('disabled', true);
		elementToDisable.closest('fieldset').find('select').prop('disabled', true);
	}

});