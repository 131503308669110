$(function () {
	'use strict';

	var body = $('body');

	/* Log language
	-------------------------------------------------------------------------- */
	var lang = $('html').attr('lang');

	/* Special pageviews for 404 & search results
	-------------------------------------------------------------------------- */
	if ((body.is('#four-oh-four')) || ($('#search-result').length)) {
		if (window.dataLayer) {
			var url;

			// Is there a specific alternative url to log?
			var pageview_url = $('html').attr('data-pageview-url');

			if (pageview_url !== '') {
				url = new netr.URI(pageview_url);
			}
			else {
				url = new netr.URI(document.location.toString());
			}

			// Remove everything before path
			url.scheme = '';
			url.domain = '';
			url.port = '';

			if (body.is('#four-oh-four')) {
				// Special 404 logging
				var url404 = new netr.URI('/' + lang + '/404');
				url404.query = {
					page: document.location.pathname + document.location.search,
					from: document.referrer
				};
				url = url404.toString();
			}
			else if ($('#search-result').length) {
				// We're on a search results page, are there any hits?
				if ($('#search-result .item').length === 0) {
					// Log search with no hits in a special category
					url.query.spec_cat = 'No hits';
				}
			}

			// Track page view
			window.dataLayer.push({
				event: 'pageview',
				eventData: url.toString()
			});
		}
	}

	/* Log specific external links
	-------------------------------------------------------------------------- */
	body.on(
		'click', 'a[href="http://itunes.apple.com/se/app/sandvik-150/id496516582?mt=8&ls=1"]',
		function () {
			if (window.dataLayer) {
				var link = $(this);
				var category = 'Journey App';
				var label = 'Not set';

				window.dataLayer.push({
					event: 'journeyAppEvent',
					eventCategory: category,
					eventAction: 'View in App Store',
					eventLabel: label
				});

				// Delay to allow tracking request to finish
				setTimeout(function () {
					document.location = link.attr('href');
				}, 200);
			}
		}
	);

	/* Log careers "Apply for this job" links
	-------------------------------------------------------------------------- */
	function netrGA_split_url(url) {
		var modified_url = new netr.URI(url);
		var splitted_url = modified_url.path.split('/');
		var splitted_page = splitted_url[splitted_url.length-2];
		return splitted_page;
	}

	$('.apply').bind({
		'open.netrdialog': function () {
			if (window.dataLayer) {
				var current_page = netrGA_split_url(document.location.toString());
				var custom_url = '/' + lang + '/vp_language_chooser_' + current_page;

				window.dataLayer.push({
					event: 'virtualPageview',
					eventData: custom_url
				});
			}
		}
	});

	body.on('click', '.dialog-content .language-list a', function (e) {
		if (window.dataLayer) {
			e.preventDefault();
			var current_page = netrGA_split_url(document.location.toString());
			var link = $(this);
			var custom_url = '/' + lang + '/vp_login_' + link.text() + '_' + current_page;

			window.dataLayer.push({
				event: 'virtualPageview',
				eventData: custom_url
			});

			// Delay to allow tracking request to finish
			setTimeout(function () {
				document.location = link.attr('href');
			}, 100);
		}
	});
});
