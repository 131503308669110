document.addEventListener('DOMContentLoaded', function () {
	var filterformButton = document.querySelector('#filter-form-submitbutton');
	if (filterformButton) {
		filterformButton.disabled = false;

		var productFilterForm = document.querySelector('#submit-filter-form');

		var productRows = document.querySelectorAll('tr');

		function addIndexToInputs(filterCategories) {
			var productRowsHeaders = document.querySelectorAll('th');
			productRowsHeaders.forEach(function (row, i) {
				var rowFound = filterCategories.find(function (filterCategory) {
					if (row.textContent.includes(filterCategory.name)) {
						return filterCategory;
					}
				});
				if (rowFound) {
					var name = rowFound.name;
					filterCategories.forEach(function (filterCategory) {
						if (filterCategory.name === name) {
							filterCategory.index = i;
						}
					});
				}
			});
		}

		function getMatchingRowsAndHideRows(rows, compare) {
			rows.forEach(function (row) {
				var allTd = row.querySelectorAll('td');
				if (allTd.length > 0) {
					var disabled = false;
					allTd.forEach(function (td, i) {
						var foundCategory = compare.find(function (filterCategory) {
							if (filterCategory.index === i) {
								return filterCategory;
							}
						});
						if (
							foundCategory &&
							foundCategory.category === 'select' &&
							disabled !== true
						) {
							if (foundCategory.value !== 'all') {
								if (td.textContent !== foundCategory.value) {
									row.style.display = 'none';
									disabled = true;
								} else if (disabled !== true) {
									row.style.display = 'table-row';
								}
							} else {
								row.style.display = 'table-row';
							}
						} else if (
							foundCategory &&
							foundCategory.category === 'input' &&
							disabled !== true
						) {
							if (
								parseInt(td.textContent) < parseInt(foundCategory.minValue) ||
								parseInt(td.textContent) > parseInt(foundCategory.maxValue)
							) {
								row.style.display = 'none';
								disabled = true;
							} else if (disabled !== true) {
								row.style.display = 'table-row';
							}
						}
					});
				}
			});
		}

		var getAllInputs = function () {
			return new Promise(function (resolve, reject) {
				var allFilterInputs =
					productFilterForm.querySelectorAll('fieldset input');
				var allFilterSelects = productFilterForm.querySelectorAll('select');
				var allFieldSetLegends = productFilterForm.querySelectorAll('legend');
				var filterCategories = [];
				allFilterSelects.forEach(function (select) {
					select.labels.forEach(function (label) {
						filterCategories.push({
							category: 'select',
							name: label.textContent,
							value: select.value,
							index: undefined,
						});
					});
				});

				allFieldSetLegends.forEach(function (legend, index) {
					if (index > 0) {
						filterCategories.push({
							category: 'input',
							name: legend.innerText,
							minValue: allFilterInputs[index * 2].value,
							maxValue: allFilterInputs[index * 2 + 1].value,
							index: undefined,
							minNumberId: allFilterInputs[index * 2].id,
							maxNumberId: allFilterInputs[index * 2 + 1].id,
						});
					} else {
						filterCategories.push({
							category: 'input',
							name: legend.innerText,
							minValue: allFilterInputs[index].value,
							maxValue: allFilterInputs[index + 1].value,
							index: undefined,
							minNumberId: allFilterInputs[index].id,
							maxNumberId: allFilterInputs[index + 1].id,
						});
					}
				});
				resolve(filterCategories);
			});
		};

		function startFunction() {
			getAllInputs().then(function (filterCategories) {
				addIndexToInputs(filterCategories);
				getMatchingRowsAndHideRows(productRows, filterCategories);
			});
		}

		productFilterForm.addEventListener('submit', function (e) {
			e.preventDefault();
			getAllInputs().then(function (filterCategories) {
				addIndexToInputs(filterCategories);
				getMatchingRowsAndHideRows(productRows, filterCategories);
			});
		});
		startFunction();
	}
});
