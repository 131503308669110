$(function () {
	'use strict';

	// Hack for AR link
	$('.teaser a').each(function () {
		if (this.href && this.href.slice(-6) === '#story') {
			$(this).addClass('around-the-globe-link');
		}
	});

	$(document).on('click', '.around-the-globe-teaser a, .around-the-globe-link', function (e) {
		e.preventDefault();
		e.stopPropagation();

		var link = $(this);

		$.getFragment({
			url: link.attr('href'),
			async: false,
			success: function (data) {
				if (data.length) {
					var dialog = $.netrdialog({
						extraClass: 'dialog-dark'
					});

					dialog.setContent(data);
					dialog.open();
					dialog.dialogElement.parent().addClass('darker');
				}
			}
		});
	});

	$(document).on('click', '.around-the-globe-story-nav a', function (e) {
		e.preventDefault();
		e.stopPropagation();

		var link = $(this);
		var old_article = $('.around-the-globe-story');
		link.addClass('loading');

		$.getFragment({
			url: link.attr('href'),
			async: false,
			success: function (data) {
				// setTimeout(function () {
				link.removeClass('loading');

				old_article.find('.around-the-globe-story-nav').replaceWith(data.find('.around-the-globe-story-nav'));

				if (data.length) {
					old_article.find('h1').addClass('fade-out');
					old_article.find('.around-the-globe-story-text').addClass('fade-out');
					old_article.find('.around-the-globe-story-image img').addClass('slide-out');
					old_article.find('.around-the-globe-story-image-inner').append(data.find('.around-the-globe-story-image img'));
					old_article.find('.around-the-globe-story-number').addClass('fade-out');

					setTimeout(function () {
						old_article.find('h1').html(data.find('h1').html()).removeClass('fade-out');
						old_article.find('.around-the-globe-story-text').html(data.find('.around-the-globe-story-text').html()).removeClass('fade-out');
						old_article.find('.around-the-globe-story-number').html(data.find('.around-the-globe-story-number').html()).removeClass('fade-out');
					}, 500);

					setTimeout(function () {
						old_article.find('img.slide-out').remove();
					}, 1000);
				}

				if ($(window).scrollTop() > old_article.offset().top) {
					$('html, body').animate({
						'scrollTop': old_article.offset().top - 20
					}, 500);
				}
				// }, 500);
			}
		});
	});
});