(function ($) {
	'use strict';

	$.fn.instaPost = function () {
		return this.each(function () {
			var videos = $(this).find('video.insta-post__media-object');

			videos.on('click', function () {
				var self = this;

				if (!self.paused) {
					self.pause();
				}
				else {
					self.play();
				}
			});
		});
	};
}(jQuery));
